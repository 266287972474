<template>
    <v-autocomplete
        v-model="model[config.key]"
        :search-input.sync="search"
        :items="config.items"
        :item-text="config.itemText"
        :item-value="config.itemValue"
        flat
        hide-no-data
        :dense="config.dense"
        hide-details
        :multiple="config.multiple"
        :return-object="config.returnObject"
        :label="config.label"
        :disabled="isDisabled"
        :rules="config.rules"
        @change="config.change && config.change($event)"
    >
        <template v-slot:selection="{ item, index }">
            <span v-if="index === 0" class="mr-2">{{ item[config.itemText] }}</span>
            <span v-if="index === 1" class="grey--text text-caption">&nbsp;&nbsp;(+{{
                    model[config.key].length - 1
                }})</span>
        </template>
    </v-autocomplete>
</template>

<script>
export default {
    name: "FormServerAutoComplete",
    props: ['config', 'model', 'errors'],
    data() {
        return {
            items: [],
            search: null
        }
    },
    watch: {
        search(val) {
            val && val !== this.model[this.config.key] && this.config.search(val)
        }
    },
    computed: {
        isDisabled() {
            return this.config.isDisabled && this.config.isDisabled(this.model);
        }
    }
}
</script>

<style scoped>

</style>
